import { CategoryLocationTabs, Container, MobileGolfClubModelSearch, Sections } from '@components'
import { useModal, useUrlSearchParams, useWindowSize } from '@hooks'
import { HomePage } from '@pages/components'
import { RouteComponentProps, useLocation } from '@reach/router'
import { AccountSetupModalState, CacheUser, ResponseType } from '@types'
import React, { memo, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { user } from '@graphql'
import './home.scss'
import { navigate } from 'gatsby'

interface HomeProps extends RouteComponentProps {}

const Home: React.FC<HomeProps> = () => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const blockForScrollRef = useRef<HTMLDivElement>(null)
  const { getUrlSearchParam: getUrlSearchAuthParam } = useUrlSearchParams('authorization')
  const urlAuth = getUrlSearchAuthParam()
  const { getUrlSearchParam: getUrlSearchCategoryParam } = useUrlSearchParams('categoryIds')
  const urlCategory = getUrlSearchCategoryParam()
  const { getUrlSearchParam: getUrlForgotPasswordParam } = useUrlSearchParams('forgot-password')
  const forgotPasswordParam = getUrlForgotPasswordParam()
  const { openModal } = useModal<AccountSetupModalState>('AccountSetupModal')

  const { data: currentUserData, loading } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'network-only'
  })

  const { href } = useLocation()
  const isLogin = currentUserData?.res.id

  //TODO move this logic
  useEffect(() => {
    const token = localStorage.getItem('token')

    if (forgotPasswordParam) {
      openModal({ part: 'ForgotPassword' })
    }

    if (!loading && token && !isLogin) {
      localStorage.removeItem('token')
    }

    if (urlAuth && !token) {
      localStorage.setItem('token', urlAuth)

      window.location.reload()
    }

    const redirectUrl = localStorage.getItem('redirectUrl')
    if (redirectUrl && href.includes('authorization')) navigate(`${redirectUrl}`)
  }, [])

  useEffect(() => {
    if (urlCategory && urlCategory.length) {
      const relativeBlockPosition = blockForScrollRef.current?.getBoundingClientRect().y
      window.scrollBy(0, relativeBlockPosition || 0)
    }
  }, [urlCategory])

  return (
    <div className={'home'}>
      <section className="home-container">
        <Container>
          <HomePage.Hero />
          {isMobileScreen && (
            <div className="home-mobile-searchbar">
              <MobileGolfClubModelSearch />
            </div>
          )}
          <div ref={blockForScrollRef} />
          <Sections />
          <CategoryLocationTabs />
        </Container>
      </section>
    </div>
  )
}

export default memo(Home)
